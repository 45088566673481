<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <!-- INICIO Detalhes do processo -->
        <div class="col-md-3 text-left dark-box">

          <div class="row" >
            <div class="row col-12 mb-5">
              <div  class="row col-12 mb-3">
                <div class="col-12 text-muted">Processo:</div>
                <div class="col-12 ">{{ invoice.numeroProcesso}}</div>
              </div>

              <div v-if="invoice.referenciaCliente" class="row col-12 mb-3">
                <div class="col-12 text-muted">Referência Cliente</div>
                <div class="col-12 ">{{ invoice.referenciaCliente }}</div>
              </div>
              <div v-if="invoice.cliente" class="row col-12 mb-3">
                <div class="col-12 text-muted">Cliente</div>
                <div class="col-12 ">{{invoice.cliente}} ({{(invoice.clienteCpfCnpj) ? invoice.clienteCpfCnpj : 'N/A' }})</div>
              </div>
            </div>

            <div class="row col-12 mb-5">
              <div v-if="invoice.pessoaPadrao" class="row col-12 mb-3">
                <div class="col-12 text-muted">Pessoa da Fatura</div>
                <div class="col-12 ">{{ invoice.pessoaPadrao.nome }}</div>
              </div>

              <div v-if="invoice.tipoMovimentacaoPadrao" class="row col-12 mb-3">
                <div class="col-12 text-muted">Tipo de Movimentação</div>
                <div class="col-12 ">{{ invoice.tipoMovimentacaoPadrao.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIM Detalhes do processo -->
        <!-- Inicio timeline-->
        <div class="col-md-9">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <ul class="nav line-tabs borderless flex-center mb-5">

            <li class="nav-item">
              <a class="nav-link"  data-toggle="tab" >Taxas</a>
            </li>
          </ul>
          <!-- Taxas -->
          <div>
            <div>

              <table class="table align-middle table-clickable">
                <thead>
                <tr>
                  <th class="text-left">Taxa</th>
                  <th>Moeda</th>
                  <th>Valor Total</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(taxa, index) in invoice.taxas">
                  <tr v-bind:class="{ active: isSelectedTax(invoice, taxa) }" v-bind:key="index">
                    <td class="text-left">{{ taxa.nome }}  </td>
                    <td>{{ taxa.moedavalortotal }}</td>
                    <td>{{ $util.formatNumber(taxa.valortotal) }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          <div>
          </div>
          </div>
          <!-- FIM Taxas -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessService from '@/services/Process/ProcessService'

export default {
  name: 'PersonInfoView',
  components: { },
  methods: {
    isSelectedTax (invoice, taxa) {
      let idsTaxasSelecionadas = invoice.taxasSelecionadas.map(taxaSelecionada => {
        return parseInt(taxaSelecionada.idtaxa_logistica_exibicao || taxaSelecionada.idtaxaLogisticaExibicao)
      })

      idsTaxasSelecionadas.push(this.incomeTax.code)
      return idsTaxasSelecionadas.includes(parseInt(taxa['idtaxa_logistica_exibicao']))
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    fileDownload (idProcess, idFile, fileName) {
      this.isLoading = true
      ProcessService.getProcessFile(idProcess, idFile).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
      })
    }
  },
  computed: {
    getPais () {
      if (this.person.idpais) {
        return this.person.idpais.nome
      }

      return null
    }
  }
}
</script>

<style scoped>

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
